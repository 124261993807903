<template>
  <div class="exchange-page">
    <div class="exchange container-fluid">
      <div class="container">
        <div class="exchange-box">
          <div class="title">
            <span>{{ $t('exchange_page.title') }}</span>
          </div>
          <div class="descriptions">
            <p class="paragraph_1">
              {{ $t('exchange_page.description.paragraph_1') }}
            </p>
            <p class="paragraph_2">
              {{ $t('exchange_page.description.paragraph_2') }}
            </p>
          </div>
          <div class="start-button">
            <button>{{ $t('exchange_page.start') }}</button>
          </div>
          <div class="scroll-down">
            <span>{{ $t('exchange_page.scroll') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="arbitrage container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6 empty">
            <img src="./img/exchange.png" alt="image" />
          </div>
          <div class="col-md-6 col-sm-6 arbitrage-box">
            <div class="low-title">
              <span>{{ $t('exchange_page.arbitrage') }}</span>
            </div>
            <div class="title">
              <span>{{ $t('exchange_page.exchange') }}</span>
            </div>
            <div class="description">
              <span>{{ $t('exchange_page.exchange_description') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="market-arbitrage arbitrage container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6 arbitrage-box">
            <div class="low-title">
              <span>{{ $t('exchange_page.arbitrage') }}</span>
            </div>
            <div class="title">
              <span>{{ $t('exchange_page.market_title') }}</span>
            </div>
            <div class="description">
              <span>{{ $t('exchange_page.market_description') }}</span>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 empty">
            <img src="./img/market.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
    <div class="multi-arbitrage arbitrage container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6 empty">
            <img src="./img/arbitrage.png" alt="image" />
          </div>
          <div class="col-md-6 col-sm-6 arbitrage-box">
            <div class="low-title">
              <span>{{ $t('exchange_page.arbitrage') }}</span>
            </div>
            <div class="title">
              <span>{{ $t('exchange_page.arbitrage_title') }}</span>
            </div>
            <div class="description">
              <span> {{ $t('exchange_page.arbitrage_description') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="smart-trading container-fluid">
      <div class="container">
        <div class="smart-trading-box">
          <div class="title">
            <span>{{ $t('exchange_page.start_free') }}</span>
          </div>
          <div class="description">
            <span>{{ $t('exchange_page.free_use') }}</span>
          </div>
          <div class="start-button">
            <button>{{ $t('exchange_page.start_now') }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="cards container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 cards_accept">
            <div class="cards__title">
              <span>{{ $t('exchange_page.accept') }}</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 cards__list">
            <div class="skrill">
              <img src="~@assets/img/skrill.png" alt="skrill" />
            </div>
            <div class="visa">
              <img src="~@assets/img/visa.png" alt="visa" />
            </div>
            <div class="mc">
              <img src="~@assets/img/mc.png" alt="master_card" />
            </div>
            <div class="neteller">
              <img src="~@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="wire">
              <img src="~@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" src="./styles.scss"></style>
